.about_game {
  background: #424242;
  padding: 15px 10px 1px;
  border-radius: 3px;
  margin-bottom: 30px;
}
.about_game p {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 18px;
}
@media (max-width: 767px) {
  .advertisment_img {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 577px) {
  .about_col {
    padding-left: 0;
  }
}
@media (max-width: 576px) {
  .about_game {
    margin-top: 20px;
    padding: 15px 20px;
  }
  .advertisment_img {
    padding: 0 15px;
  }
  .advertisment_img img {
    max-width: 100%;
  }
}
