
/* fonts css */

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-Book.eot');
    src: url('fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-Book.woff2') format('woff2'),
        url('fonts/Gotham-Book.woff') format('woff'),
        url('fonts/Gotham-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-Light.eot');
    src: url('fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-Light.woff2') format('woff2'),
        url('fonts/Gotham-Light.woff') format('woff'),
        url('fonts/Gotham-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-BookItalic.eot');
    src: url('fonts/Gotham-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-BookItalic.woff2') format('woff2'),
        url('fonts/Gotham-BookItalic.woff') format('woff'),
        url('fonts/Gotham-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-BoldItalic.eot');
    src: url('fonts/Gotham-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-BoldItalic.woff2') format('woff2'),
        url('fonts/Gotham-BoldItalic.woff') format('woff'),
        url('fonts/Gotham-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-Medium.eot');
    src: url('fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-Medium.woff2') format('woff2'),
        url('fonts/Gotham-Medium.woff') format('woff'),
        url('fonts/Gotham-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-MediumItalic.eot');
    src: url('fonts/Gotham-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-MediumItalic.woff2') format('woff2'),
        url('fonts/Gotham-MediumItalic.woff') format('woff'),
        url('fonts/Gotham-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-LightItalic.eot');
    src: url('fonts/Gotham-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-LightItalic.woff2') format('woff2'),
        url('fonts/Gotham-LightItalic.woff') format('woff'),
        url('fonts/Gotham-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-Bold.eot');
    src: url('fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-Bold.woff2') format('woff2'),
        url('fonts/Gotham-Bold.woff') format('woff'),
        url('fonts/Gotham-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-Medium_1.eot');
    src: url('fonts/Gotham-Medium_1.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-Medium_1.woff2') format('woff2'),
        url('fonts/Gotham-Medium_1.woff') format('woff'),
        url('fonts/Gotham-Medium_1.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-Bold_1.eot');
    src: url('fonts/Gotham-Bold_1.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-Bold_1.woff2') format('woff2'),
        url('fonts/Gotham-Bold_1.woff') format('woff'),
        url('fonts/Gotham-Bold_1.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-ThinItalic.eot');
    src: url('fonts/Gotham-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-ThinItalic.woff2') format('woff2'),
        url('fonts/Gotham-ThinItalic.woff') format('woff'),
        url('fonts/Gotham-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-Light_1.eot');
    src: url('fonts/Gotham-Light_1.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-Light_1.woff2') format('woff2'),
        url('fonts/Gotham-Light_1.woff') format('woff'),
        url('fonts/Gotham-Light_1.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-Black.eot');
    src: url('fonts/Gotham-Black.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-Black.woff2') format('woff2'),
        url('fonts/Gotham-Black.woff') format('woff'),
        url('fonts/Gotham-Black.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-XLight.eot');
    src: url('fonts/Gotham-XLight.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-XLight.woff2') format('woff2'),
        url('fonts/Gotham-XLight.woff') format('woff'),
        url('fonts/Gotham-XLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-UltraItalic.eot');
    src: url('fonts/Gotham-UltraItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-UltraItalic.woff2') format('woff2'),
        url('fonts/Gotham-UltraItalic.woff') format('woff'),
        url('fonts/Gotham-UltraItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-Thin.eot');
    src: url('fonts/Gotham-Thin.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-Thin.woff2') format('woff2'),
        url('fonts/Gotham-Thin.woff') format('woff'),
        url('fonts/Gotham-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-BookItalic_1.eot');
    src: url('fonts/Gotham-BookItalic_1.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-BookItalic_1.woff2') format('woff2'),
        url('fonts/Gotham-BookItalic_1.woff') format('woff'),
        url('fonts/Gotham-BookItalic_1.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-XLightItalic.eot');
    src: url('fonts/Gotham-XLightItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-XLightItalic.woff2') format('woff2'),
        url('fonts/Gotham-XLightItalic.woff') format('woff'),
        url('fonts/Gotham-XLightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}





body {
	font-family: 'Gotham';
	font-weight: 300;
    font-size: 13px;
}

.body_bg_day {
  background-color: #4e4e4e;
  color: #909a9c;
}
.body_bg_night {
  background-color: #a4a4a7;
  color: #909a9c;
  min-height: 100vh;
}
.main_container {
	padding-top: 30px;
	padding-bottom: 50px;
}
h1, h2, h3, h4, h5, h6 {
	color: #cecece;
}
h1 {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 14px;
}
h2 {
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 8px;
}
.btn-solid, .btn-wire {
    padding: 3px 18px 3px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 300;
    border-radius: 3px;
    border: 1px solid #6d6d6d;
}
.btn-solid {
	background-color: #6d6d6d;
}
.btn-wire {
	color: #d1d1d1;
	background-color: transparent;
}
.body_bg_night .btn-wire {
    color: #424242;
    border-color: #424242;
}
.body_bg_night .btn-wire:hover {
    color: #fff;
}
.body_bg_night h1 {
    /* color: #424242; */
}
.hide-pause{
    display: none;
}


/* responsive */
@media (min-width: 577px) {
    .leaderboard_col {
        padding-left: 0px;
    }
}
@media (min-width: 991px) {
	.container {
	    max-width: 780px;
    }	
    .hide-desktop{ display: none;}
}
@media (min-width: 768px) {
    .container {
        max-width: 760px;
    }   
    li.nav-item button {
        /* display: none; */
    }
}
@media (max-width:767px) and (min-width:576px) {
    .leaderboard_col {
        padding-left: 15px;
    }
    .hide-mobile {
        display: none !important;
    }
    
    .container_game_over.mobile-top {
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 5;

    width: 100%;
    background: #424242;
    }
    .rankboard_container {
        padding-right: 0px;
        position: ABSOLUTE;
        LEFT: 0;
        Z-INDEX: 1;
        border-radius: 0;
    }
    .rankboard{ border-radius: 0px;}
    .main_container{ padding-top: 0px; padding-bottom: 0px; background: #424242; height: 91vh;}
    .body_bg_night {
        background-color: #a4a4a7;
        color: #909a9c;
        min-height: auto;
        overflow: hidden;
      }
      .equatiobox-outer {        
        justify-content: center;       
        padding: 9px 0;
        /* height: 88vh; */
        overflow: hidden;
        margin: 0px !important;
    }
    .container_game_over .equatiobox-outer{ display: flex !important; align-items: center;}
}

@media (max-width: 576px) {
    .equatiobox-outer {        
        justify-content: center;       
        padding: 9px 0;
        height: 88vh;
        overflow: hidden;
        margin: 0px !important;
    }
    .container_game_over .equatiobox-outer{ display: flex !important; align-items: center;}
    .body_bg_night {       
        min-height: auto;
        overflow: hidden;
      }
    .main_container{ padding-top: 0px; padding-bottom: 0px; background: #424242; height: 91vh;}
    .rankboard_container {
        padding-right: 0px;
        position: ABSOLUTE;
        LEFT: 0;
        Z-INDEX: 1;
        border-radius: 0;
    }
    .rankboard{ border-radius: 0px;}
    .hide-mobile {
        display: none !important;
    }
    .container_game_over.mobile-top {
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 5;
    width: 100%;
    background: #424242;
    }
	.btn-solid, .btn-wire {
		display: block;
		width: 100%;
		max-width: 100%;
		margin: 8px 0;
        font-size: 14px;
        padding-top: 6px;
        padding-bottom: 5px;
	}
    h1 {
        font-size: 15px;
        font-weight: normal;
    }
    h2 {
        font-size: 19px;
        font-weight: normal;
    }
}
.rankboard_container {
    padding-left: 0px;
}
.rankboard {
    background-color: #424242;
    border-radius: 5px;
    padding: 15px 20px;
    margin-bottom: 13px;
    line-height: 1.1;
}
.rankboard .title {
    font-size: 14px;
    font-weight: normal;
    color: #cecece;
}
.numeric_value {
    font-size: 32px;
    color: #d1d1d1;
    font-weight: 800;
    display: inline-block;
}
.numeric_value span {
    font-size: 13px;
    font-weight: normal;
    color: #909a9c;
    padding-left: 6px;
}
.palyed_col {
    display: flex;
    justify-content: center;
}
.rank_col {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 576px) {
    .rankboard {
        line-height: 1.3;
        padding: 15px;
        border-radius: 0px;
        
    }
    .numeric_value {
        font-size: 18px;
        font-weight: 400;
        margin-top: 5px;
    }
    .rankboard .title {
        color: #909a9c;
    }
}
@media (max-width: 767px) {
    .rankboard_container {
        padding-right: 0px;
    }
    .hide-pause{
        display: block;
        margin-top: 35px;
    }
    .rankboard .col {
        width: 18%;
    }
    .rankboard .col:first-child {
        width: 25%;
    }
    .container_game_over .equatiobox-outer button {
        position: relative;
        top: 0;
    }
}