.mb-30 {
  margin-bottom: 30px !important;
}
.avail_num span{
  display: inline-block;
  margin: 0 15px 0 0;
  font-size: 52px;
  line-height: 52px;
  width: 60px;
  height: 60px;
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid #000;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  padding: 0;
  text-align: center;
}
.input-wrapper{
  display: block;
  float: left;
  border: 2px solid #dedede;
  margin: 0 15px 20px 0;
  padding: 0;
  width: 60px;
  height: 60px;
  font-size: 42px;
  text-align: center;
}
.input-wrapper .input-field{
  border: none;
  padding: 0;
  margin: 0;
  width: 56px;
  height: 56px;
  font-size: 42px;
  line-height: 68px;
  color: #000;
  text-align: center;
  -webkit-appearance: none;
  vertical-align: top;
  border: 2px solid #fff;
}
.bor-0{
  border: none;
}


/*swiper slider css*/
.swiper_outer {
  position: relative;
  padding-top: 0px;
  padding-bottom: 0px;
  display: inline-block;
  overflow: hidden;
  margin: 0 3px;
}
.customized-swiper-container {
  margin: 0;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  width: 50px;
  height: 50px;
  border:0px solid transparent;
  padding: 0;
  background-color: transparent;
}
.customized-swiper-container:after,
.customized-swiper-container:before {
  position: absolute;
  content: "";
  display: none;
  height: 26px;
  width: 50px;
  left: 0px;
  background-color: #424242;
  z-index: 10;
  box-shadow: none;
}
.customized-swiper-container:after {
  top: -0px;
}
.customized-swiper-container:before {
  bottom: -1px;
}
.swiper-container-vertical>.swiper-wrapper {
  background-color: #747475;
  border-radius: 5px;
}
.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent !important;
  text-align: center;
  color: transparent;
  font-size: 26px;
  font-weight: 800;
  border:0px;
  border-radius: 0px;
  border-radius: 2px;
}
.level-slides{
  color: #d1d1d1;
  background: #4c4c4c !important;
}
.swiper-slide-active {
  color: #d1d1d1;
  background-color: #747475 !important;
}
.swiper-button-next,
.swiper-button-prev {
  top: auto;
  left: 0 !important;
  right: 0 !important;
  height: 50px;
  width: 50px;
  margin-top: 0px;
  margin-left: 0px;
  right: auto;
  transform: rotate(90deg);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background-color: #747475;
  /*background-image: none;*/
  border-radius: 3px;
  padding: 3px;
  margin-left: 0px;
  font-size: 21px;
  color: #393939;
  z-index: 100;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px;
}
.swiper-button-prev {
  display: none;
}
.swiper-button-next {
  bottom: 0px !important;
  background: transparent !important;
}
.equatiobox-outer {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  justify-content: center;
  background-color: #424242;
  border-radius: 3px;
  padding: 9px 6px 11px;
  flex-wrap: wrap;
  min-height: 400px;
  position: relative;
  overflow: hidden;
}
.equationBox {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0px;
  min-height: auto;
  position: relative;
}
/* .equationBox .repeat-section:not(:first-child){ margin-top: -26px;
  position: relative;
  z-index: 2;} */

  /* .equationBox .repeat-section:not(:first-child):hover{ position: relative; z-index: 9999;} */



/* .icon_equal {
  width: 50px;
  text-align: center;
  font-size: 36px;
  color: #d1d1d1;
} */
.icon_equal {
  width: 50px;
  text-align: center;
  font-size: 36px;
  color: #d1d1d1;
  align-items: center;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  justify-content: center;
}
.repeat-section {
  flex-direction: row;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  position: relative;
  z-index: 999;
}
.avail_digit {
  padding: 30px 0 0;
}
.avail_digit h1 {
  text-transform: uppercase;
  margin-bottom: 14px;
}
.avail_num span {
  background-color: #fff;
  border-radius: 3px;
  height: 55px;
  width: 55px;
  border:0;
  color: #bdbbbb;
  font-size: 30px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.avail_num {
  padding-bottom: 35px;
}

.body_bg_night h1{
  text-align: center;
}
 
.body_bg_night .movers_taken, .body_bg_night .time_taken {
  color: #424242;
  font-weight: normal;
}
.time_taken {
  display: flex;
  align-items: center;
  justify-content: center;
}
.time_taken h3{
  font-size: 12px;
  margin: 0;
  font-weight: normal;
  color: #424242;
  padding-top: 3px;
  padding-left: 20px;
  background-image: url('../../Icons/time_taken_icon_dark.png');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 18px;
}
.body_bg_day .time_taken h3 {
  background-image: url('../../Icons/time_taken_icon.png');
}
.body_bg_day .time_taken h3, .body_bg_day .movers_taken {
  color: #cecece;
}
.loader_text {
  font-size: 16px;
  color: #d1d1d1;
  margin-right: 10px;
}
.movers_taken {
  padding-left: 17px;
  background-image: url('../../Icons/move_dark.png');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 14px;
}
.body_bg_day .movers_taken {
  background-image: url('../../Icons/move.png');
}
.equationbox_container {
  padding-left: 0px;
}
/* progress bar css */
.progressbar_outer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 13px;
  padding-right: 60px;
}
.progress-bar {
  position: relative;
  height: 4px;
  width: 100%;
  min-width:200px ;
  border-radius: 50px;
  border: 0px solid #333;
  background:#747475;
}

.filler {
  background: #424242;
  height: 100%;
  border-radius: inherit;
  transition: width .5s ease-in-out;
}

button {
  margin-right: 10px;
  padding: 7px 12px;
  font-size: 14px;
  background: #d14836;
  color: white;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  outline: 0;
}


/* new grid system */
.equationBox .equationRow{
  padding: 2px;
  margin: 2px;
  cursor: pointer;
  width: 50px;
  text-align: center;
  background: #747475;
  color: #d1d1d1;
  font-size: 26px;
  height: 50px;
  line-height: 46px;
  font-weight: 800;
  border: 0px;
  border-radius: 3px;
}
.equationRow.eqautionbox_equals {
  background: transparent;
  color: #d1d1d1;
}


@media (max-width: 1200px) {
  /* .equationbox_container {
    margin-bottom: 30px;
  } */
}

@media (max-width: 767px) {
  .equationbox_container {
    padding: 0px;
  }
  /* .equatiobox-outer {
    padding: 0px;
  } */
  /* .customized-swiper-container {
    width: 60px;
    height: 140px;
    padding: 40px 0;
  } */
  /* .customized-swiper-container:after, .customized-swiper-container:before {
    height: 40px;
    width: 60px;
  } */
  /* .swiper-button-next, .swiper-button-prev {
    height: 60px;
    margin-left: 13px;
  } */
  /* .swiper_outer:hover .swiper-button-next {
    bottom: -15px;
  } */
  /* .swiper_outer:hover .swiper-button-prev {
    top: -15px;
  } */
  .swiper-slide {
    padding: 0;
    font-size: 5vw;
    font-weight: 400;
  }
  .avail_digit {
    text-align: center;
  }
  .avail_num span {
    font-weight: 400;
    font-size: 5vw;
  }
  .instructions_container {
    padding-right: 0px;
  }
}

@media (max-width: 576px) {
  /* .equatiobox-outer {
    padding: 0;
    background-color: transparent;
  } */
  .equationbox_container {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  }
  .equationbox_container h1 {
    margin-bottom: 0;
  }
  .equationBox {
    background-color: transparent;
    padding-right: 0;
    padding-left: 0;
  }
  .customized-swiper-container {
    color: #808080;
    border-radius: 3px;
  }
  .swiper-slide {
    font-size: 6.5vw;
    font-weight: 300;
    padding: 0px;
    border-radius: 4px;
  }
  .swiper-slide-active {
    background-color: #747474 !important;
  }
  .icon_equal {
    width: 30px;
    font-size: 32px;
    font-weight: 300;
    color: #000;
  }
  .avail_digit {
    padding-top: 20px;
  }
  .avail_num {
    padding-bottom: 20px;
  }
  .avail_num span {
    width: 50px;
    height: 50px;
    background: #717171;
    color: #d1d1d1;
    font-weight: 300;
    font-size: 7vw;
    margin-right: 8px;
  }
  .avail_num span:last-child {
    margin-right: 0px;
  }
  .avail_digit h1 {
    margin-bottom: 15px;
  }
  /* .customized-swiper-container:after, .customized-swiper-container:before {
    background-color: #747475;
  } */
  /* .body_bg_day .customized-swiper-container:after,
  .body_bg_day .customized-swiper-container:before {
    background-color: #4e4e4e;
  } */
  /* .body_bg_night .customized-swiper-container:after,
  .body_bg_night .customized-swiper-container:before {
    background-color: #a4a4a7;
  } */
}

@media (max-width: 480px) {
  .customized-swiper-container {
    height: 46px;
    width: 46px;
  }
  /* .swiper-button-next, .swiper-button-prev {
    height: 44px;
    margin-left: 12px;
    width: 20px;
    border-radius: 3px;
  } */
  /* .swiper_outer:hover .swiper-button-next {
    bottom: -1px;
  } */
  /* .swiper_outer:hover .swiper-button-prev {
    top: -1px;
  } */
  .avail_digit {
    padding-top: 10px;
  }
  .progress-bar {
    min-width:130px ;
  }
  .equationBox .equationRow {
    width: 42px;
    height: 42px;
    line-height: 36px;
  }
}

@media (max-width: 360px) {
  .customized-swiper-container {
    height: 42px;
    width: 42px;
  }
  .equatiobox-outer {
    min-height: 400px;
  }
  /* .customized-swiper-container {
    height: 122px;
    width: 39px;
  } */
  /* .swiper-button-next, .swiper-button-prev {
    height: 39px;
    margin-left: 9px;
  } */
  .swiper_outer {
    margin: 0 2px;
  }
  /* .swiper-slide {
    border-radius: 5px;
  } */
  .equationBox .equationRow {
    width: 35px;
    height: 35px;
    font-size: 20px;
    line-height: 31px;
  }
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -800px, 0);
    transform: translate3d(0, -800px, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -800px, 0);
    transform: translate3d(0, -800px, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}
.animated{
  animation-duration:1s;animation-fill-mode:both
}
@keyframes progresswidth  {
  0%,from { 
    width: 100%;
  }
  100%,to { 
    width: 0;
  }
}
.progresswidth{
  animation-name:progresswidth;
  /* animation-duration: 20s; */
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 10;
}
.hide{
  display: none;
}
.popup_inner {
  background: #a4a4a7;
  padding: 20px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 15px 6px rgba(0,0,0,0.08);
          box-shadow: 0px 0px 15px 6px rgba(0,0,0,0.08);
  z-index: 100;
  text-align: center;
}
.popup_inner .btn {
  font-size: 14px;
}

.isHighlighted{
  border: 1px solid #e03133 !important;
}
.equationbox-inner {
  display: flex;
  display: -ms-flexbox;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0px;
  min-height: auto;
}
.equationRowOperator div {
  display: none;
}
.equationRowOperator div:first-child {
  display: inline-block;
}
.instructions_container {
  padding-left: 0px;
}
.instruction-wrapper{
  background: #424242;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 30px;
}


/*Fade out Left animation*/
@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    max-height: 0%;
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

.equationRow.eqautionbox_equals.unsolved {
  color: #ff0000!important;
}

.equationRow.eqautionbox_equals.solved {
  color: #4FB746!important;
}

.highlight-row .equationRow {
  background-color: #595858;
}

.highlight-row .equationRow:nth-child(4){ 
  background: transparent;
}

.highlight-row .highlight-box{
  color: #70e647;
}
.highlight-row .equationRowOperator.highlight-box{
  
  color: #70e647;
  
}
/* .highlight-row .equationRowOperator.highlight-box:before {
  position: absolute;
  content: "";
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #4FB746;
  left: 50%;
  margin-left: -2px;
  bottom: 6px;
  z-index: 10;
} */

.highlight-row .equationNumber.highlight-box{
  animation: blinker 1s linear infinite;
  position: relative;
  color: #70e647;
  }
/* .highlight-row .equationNumber.highlight-box:before {
  position: absolute;
  content: "";
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #4FB746;
  left: 50%;
  margin-left: -2px;
  bottom: 6px;
  z-index: 10;
} */
.highlight-row .cursor-box{
  color: white;
}
.highlight-row .equationNumber.cursor-box,
.highlight-row .equationRowOperator.cursor-box{
  text-decoration: none;
  position: relative;
  
  }
/* .highlight-row .equationRowOperator.cursor-box{
  text-decoration: underline;
  } */

 
.highlight-row .equationNumber.cursor-box:before,
.highlight-row .equationRowOperator.cursor-box::before
{
  position: absolute;
  content: "";
  height: 2px;
  width: 14px;
  background-color: #fff;
  left: 50%;
  margin-left: -7px;
  bottom: 5px;
  z-index: 10;
}
.highlight-row .equationNumber.highlight-box:before{
  background-color: #70e647 ;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.layercount-temp {
  position: absolute;
  right: 3px;
  font-size: 10px;
  top: -13px;
}

.highlight-yellow{
  color: #d0c920!important;
}
.container_game_over {
  padding-right: 15px;
}
.container_game_over .equatiobox-outer {
  display: block; 
  font-size: 20px;
  color: #fff;
  text-align: center;
  padding-top: 25px;
  font-weight: 400;
}
.game_over {
  color: #ff0000;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.8px;
}

@media (max-width:767px) {
  .container_game_over {
    padding-right: 0px;
  }
  .no-padding-mobile-view{ padding: 0px;}
}

.play-pause-btn {
  position: absolute;
  top: -5px;
  right: 17px;
  padding: 0;
  width: 11px;
  height: 14px;
  border: 0;
  border-radius: 3px;
  background: transparent;
  margin-right: 0px;
}
.play-pause-btn:hover,
.play-pause-btn:focus,
.play-pause-btn:active {
  border: 0px;
  outline: none;
}

.eq-overlay{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  /* z-index: 9999; */
  background: #424242;
  background-image: url('../../Icons/logo.gif');
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center;

}

.uim-btn-play{
  background-image: url(../../Icons/play.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.uim-btn-pause{
  background-image: url(../../Icons/pause.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.progress-bar-container-wrap{
  visibility: hidden;
  position: relative
}
.battery_charging {
  font-size: 18px;
  padding: 0px;
  background: #48d037;
  color: black;
  position: relative;
  top: 260px;
  left: 50%;
  transform: translateX(-50%);
  height: 35px;
  display: inline-block;
  min-width: 150px;
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  overflow: hidden;
  font-size: bold;
 
 }
 .battery_charging_inner {
  position: relative;
  right: 0;
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 19px 0 13px;
 
 }
 .battery_charging_inner:before {
  position: absolute;
  background: #48d037;
  padding: 0 15px;
  width: 100%;
  content: "";
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  -webkit-animation: widthani 1.5s infinite;
          animation: widthani 1.5s infinite;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
 }
 
 
 @-webkit-keyframes widthani {
  from, 0% {
    left: 0;
  }
 
  to, 100% {
    left: 100%;
  }
 }
 
 @keyframes widthani {
  from, 0% {
    left: 0;
  }
 
  to, 100% {
    left: 100%;
  }
 }
 @media (max-width:991px) {
  .play-pause-btn { 
    right: 4px;
  }
  .equationbox-inner{ width: 100%;}
  .equationBox .equationRow {
    width: 13%;
    height: 48px;
    line-height: 44px;
}
.equationBox{ width: 100%;}

 }
 @media (max-width:760px) {
  .play-pause-btn { 
    right: 4px;
    display: none;
  }
  .equatiobox-outer button {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    max-width: 100px;
}
 }