.leadersboard {
	background: #424242;
	padding: 15px 0px 10px;
	border-radius: 3px;
	margin-bottom: 30px;
	margin: 0 auto;
	font-size: 12px;
}
.leadersboard_top {
	padding: 0 15px;
}
.leader {
	text-align: center;
	margin-bottom: 12px;
	line-height: 1.3;
}
.leader .leader_avatar {
	height: 35px;
	width: 35px;
	border-radius: 50%;
	border:1px solid #909a9c;
	margin: 0 auto 5px;
	overflow: hidden;
}
.leader .leader_avatar img {
	max-width: 100%;
}
.leader.leader1 .leader_avatar {
	height: 55px;
	width: 55px;
}
.leaderboard_heading {
	padding: 5px 0;
	text-align: center;
	font-size: 14px;
	background: #3a3a3a;
}
.leader_row {
	padding: 7px 0;
}
.leader_row .col-4 {
	padding: 0 5px;
}
.leader_row:hover {
	background-color: #8a8787;
	color: #424242;
}
.leader_rank, .leader_score {
	text-align: center;
}
.leader_name {
	padding-left: 25px;
	position: relative;
	white-space:nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.leader_row .leader_avatar {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	display: inline-flex;
	border:1px solid #909a9c; 
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	overflow: hidden;
}
.leader_row .leader_avatar img {
	max-width: 100%;
}
.leadersranking {
    margin: 0px;
    overflow-x: hidden;
    padding: 0px;
}
.leadersranking .row {
	margin: 0;
}
.thumb-vertical{
	background-color: rgb(116, 116, 116);
}

@media (max-width: 576px) {
  .navbar-nav {
    flex-direction: row;
  }
 }