.logo_outer{
	display: block;
    margin: 0;
    padding: 0;
    width: 96px;
    height: auto;
    text-align: center;
}
.logo_outer .logo{
	max-width: 100%;
}
.avatar_img{
	vertical-align: middle;
    width: 50px;
    height: 50px;
}

/* header css */
.logo_outer {
    width: 90px;
}
.navbar {
    padding: 0.25rem 0;
    border-bottom: 1px solid rgba(255,255,255,0.1);
}
.nav-item {
    display: flex;
    align-items: center;
    padding: 0 5px;
    flex-wrap: wrap;
    position: relative;
}
.nav-item:first-child {
    padding-left: 0px;
}
.nav-item:first-child img {
    max-width: 14px;
    margin-top: -1px;
    margin-right: -12px;
}
.nav-item:last-child {
    padding-right: 0px;
    border-right: 0px;
}
.navbar-expand-sm .navbar-nav .nav-link {
    padding-left: 0px;
    padding-right: 0px;
}
.avatar_img {
    border: 1px solid #d0d0d0;
    height: 35px;
    width: 35px;
    border-radius: 50%;
}
.dropdown-toggle.nav-link:after {
    display: none;
}
.react-switch-bg {
    background-color: transparent !important;
    border:1px solid #d1d1d1;
}
.react-switch-handle {
    background-color: #4e4e4e !important;
    display: none !important;
}
.switcher-label {
    margin-bottom: 0px !important;
    margin-top: 7px !important;
}
.react-switch-bg + div {
    display: flex;
    align-items: center;
}
.dayIcon {
    margin-top: -7px;
    margin-left: 3px;
}
.nightIcon {
    margin-top: -1px;
    margin-right: -6px;
}



.wrapper{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 400px;
    margin: 50vh auto 0;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.switch_box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 200px;
    min-width: 200px;
    height: 200px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

@-moz-document url-prefix() {
    .light_icon {
        margin-top: 0px;
    }
  }

    
/* Switch 3 Specific Style Start */

.box_3{
    background: #19232b;
}

.toggle_switch{
  width: 50px;
  height: 23px;
  margin-top: 4px;
  position: relative;
}

input[type="checkbox"].switch_3{
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  outline: 0;
  z-index: 1;
}

svg.checkbox .outer-ring{
  stroke-dasharray: 375;
  stroke-dashoffset: 375;
  -webkit-animation: resetRing .35s ease-in-out forwards;
          animation: resetRing .35s ease-in-out forwards;
}

input[type="checkbox"].switch_3:checked + svg.checkbox .outer-ring{
  -webkit-animation: animateRing .35s ease-in-out forwards;
          animation: animateRing .35s ease-in-out forwards;
}

input[type="checkbox"].switch_3:checked + svg.checkbox .is_checked{
  opacity: 1;
  -webkit-transform: translateX(0) rotate(0deg);
          transform: translateX(0) rotate(0deg);
}

input[type="checkbox"].switch_3:checked + svg.checkbox .is_unchecked{
  opacity: 0;
  -webkit-transform: translateX(-200%) rotate(180deg);
          transform: translateX(-200%) rotate(180deg);
}


svg.checkbox{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

svg.checkbox .is_checked{
  opacity: 0;
  fill: yellow;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transform: translateX(200%) rotate(45deg);
          transform: translateX(200%) rotate(45deg);
  -webkit-transition: all .35s;
  transition: all .35s;
}

svg.checkbox .is_unchecked{
  opacity: 1;
  fill: #fff;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transform: translateX(0) rotate(0deg);
          transform: translateX(0) rotate(0deg);
  -webkit-transition: all .35s;
  transition: all .35s;
}

@-webkit-keyframes animateRing{
  to{
    stroke-dashoffset: 0;
    stroke: #d1d1d1;
  }
}

@keyframes animateRing{
  to{
    stroke-dashoffset: 0;
    stroke: #d1d1d1;
  }
}

@-webkit-keyframes resetRing{
  to{
    stroke-dashoffset: 0;
    stroke: #d1d1d1;
  }
}

.user-image-main {
    float: left;
    margin-right: 10px;
}
.user-image-main div {
    padding: 0;
}
.user-image-main div img {
    width: 55px;
    height: 55px;
}
.navbar .dropdown-item{ padding: .25rem 0.5rem;}
.navbar-expand-sm .navbar-nav .dropdown-menu {
    width: 250px;
    position: absolute;
}
.dropdown-item:focus, .dropdown-item:hover{ background: transparent;}
.navbar .dropdown-item a {
    padding: 10px 0;
    display: inline-block;
    color: #36c;
}
.user_name {
    font-weight: bold;
}

@keyframes resetRing{
  to{
    stroke-dashoffset: 0;
    stroke: #d1d1d1;
  }
}

/* Switch 3 Specific Style End */

@media (max-width:480px) {
    .light_icon {
        margin-top: 0px;
        position: relative;
        top: -1px;
    }
}

.navbar-nav .dropdown-menu{ position: absolute;}
@media (min-width: 760px){
    i.fa.fa-info-circle.iinstruction {
        display: none;
    }
    i.fa.fa-universal-access.player{
        display: none;
    }
}


@media (max-width: 760px){
    i.fa.fa-info-circle.iinstruction {
        font-size: 30px;
        color: #ccc;
        margin-right: 12px;
    }
    i.fa.fa-universal-access.player {
        font-size: 27px;
        color: #ccc;
        margin-right: 12px;
    }
}